import axios from "axios"

//apply base url for axios
const API_URL = process.env.REACT_APP_URL_SERVER;
const API_KEY = process.env.REACT_APP_APIKEY;
const API_KEY_ARQUIVOS = process.env.REACT_APP_APIKEY_ARQUIVOS;

const axiosApi = axios.create({
  baseURL: API_URL
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  if(localStorage.getItem('token')) {
    axiosApi.defaults.headers.common["Authorization"] = 'Bearer '+localStorage.getItem('token').toString();
    axiosApi.defaults.headers.common["x-api-key"] = API_KEY;
  }
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}


export async function post(url, data, config = {}) {
  
  if(localStorage.getItem('token')) {
    axiosApi.defaults.headers.common["Authorization"] = 'Bearer '+ localStorage.getItem('token').toString();
    axiosApi.defaults.headers.common["x-api-key"] = API_KEY;
  }
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function postGerarXls(url, data, config = {}) {
  
  if(localStorage.getItem('token')) {
    axiosApi.defaults.headers.common["Authorization"] = 'Bearer '+ localStorage.getItem('token').toString();
    axiosApi.defaults.headers.common["x-api-key"] = API_KEY_ARQUIVOS;
  }
  axiosApi.baseURL = null;
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  if(localStorage.getItem('token')) {
    axiosApi.defaults.headers.common["Authorization"] = 'Bearer '+localStorage.getItem('token').toString();
    axiosApi.defaults.headers.common["x-api-key"] = API_KEY;
  }
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  if(localStorage.getItem('token')) {
    axiosApi.defaults.headers.common["Authorization"] = 'Bearer '+ localStorage.getItem('token').toString();
    axiosApi.defaults.headers.common["x-api-key"] = API_KEY;
  }
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
