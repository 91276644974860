//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_LOGIN = "/usuario/login"


//USUARIOS
export const GET_USUARIOS = "usuario/paginado"
export const URL_USUARIOS = "usuario/"

//CLIENTES
export const GET_CLIENTES = "cliente/paginado"
export const URL_CLIENTES = "cliente/"

//EXPORTADORES
export const GET_EXPORTADORES = "exportador/paginado"
export const URL_EXPORTADORES = "exportador/"

//FORNECEDORES
export const GET_FORNECEDORES = "fornecedor/paginado"
export const URL_FORNECEDORES = "fornecedor/"

//PRODUTOS
export const GET_PRODUTOS = "produto/paginado"
export const GET_EXPORTACAO_PRODUTOS = "produto/exportar"
export const URL_PRODUTOS = "produto/"
export const URL_PRODUTO_IMAGEM = "imagem/"


//PRODUTOS
export const GET_ORCAMENTOS = "orcamento/paginado"
export const URL_ORCAMENTOS = "orcamento/"
