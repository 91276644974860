import axios from "axios"
import { post, del, get, put, postGerarXls } from "./api_helper"
import * as url from "./url_helper"


import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_URL_SERVER;
const API_KEY = process.env.REACT_APP_APIKEY;

const axiosApi = axios.create({
  baseURL: API_URL,
  headers:{
    "Content-Type":"application/json",
    "x-api-key": API_KEY
  },
  timeout: 30000
})

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}


//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}


// Login Method
const postJwtLogin = data =>  {
  
  return axiosApi.post(url.POST_LOGIN, data).then(response => {
    if (response.status >= 200 || response.status <= 299) return response.data
    throw response.data
  })
  .catch(err => {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 500:
            message =
              "Desculpe! algo esta errado, por favor contacte a equipe de suporte"
              toast.error(message,{
                position: toast.POSITION.TOP_RIGHT
              }) 
            break
          case 400:
            message = "Usuário ou senha invalidos!"
            toast.error(message,{
              position: toast.POSITION.TOP_RIGHT
            }) 
            break
          default:
            message = err[1]
            toast.error(message,{
              position: toast.POSITION.TOP_RIGHT
            }) 
            break
      }
    }
    throw message
  })
}
//usuarios
export const getUsuarios = acao  =>  
get(
  `${url.GET_USUARIOS}/pagina=${acao.pagina}&tamanho=${acao.tamanho}&email=${acao.email ? acao.email:''}`)

export const createUsuario = usuario => post(url.URL_USUARIOS, usuario)

export const getUsuario = idUsuario => get(`${url.URL_USUARIOS}${idUsuario}`)

export const editUsuario = usuario => put(`${url.URL_USUARIOS}${usuario.idUsuario}`,usuario)

export const deleteUsuario  = idUsuario => del(`${url.URL_USUARIOS}${idUsuario}`)


//clientes
export const getClientes = acao  =>  
get(
  `${url.GET_CLIENTES}/pagina=${acao.pagina}&tamanho=${acao.tamanho}&nome=${acao.nome ? acao.nome:''}&cnpj=${acao.cnpj ? acao.cnpj:''}&uf=${acao.uf ? acao.uf:''}`)

export const createCliente = cliente => post(url.URL_CLIENTES, cliente)

export const getCliente = idCliente => get(`${url.URL_CLIENTES}${idCliente}`)

export const editCliente = cliente => put(`${url.URL_CLIENTES}${cliente.idCliente}`,cliente)

export const getClientesAll = () => get(`${url.URL_CLIENTES}`)

export const deleteCliente  = idCliente => del(`${url.URL_CLIENTES}${idCliente}`)

//fornecedores
export const getFornecedores = acao  =>  
get(
  `${url.GET_FORNECEDORES}/pagina=${acao.pagina}&tamanho=${acao.tamanho}&nome=${acao.nome ? acao.nome:''}&idExportador=${acao.idExportador ? acao.idExportador:''}`)

export const createFornecedor = fornecedor => post(url.URL_FORNECEDORES, fornecedor)

export const getFornecedor = idFornecedor => get(`${url.URL_FORNECEDORES}${idFornecedor}`)

export const editFornecedor = fornecedor => put(`${url.URL_FORNECEDORES}${fornecedor.idFornecedor}`,fornecedor)

export const deleteFornecedor  = idFornecedor => del(`${url.URL_FORNECEDORES}${idFornecedor}`)

export const getFornecedoresAll = () => get(`${url.URL_FORNECEDORES}`)

//exportadores
export const getExportadores = acao  =>  
get(
  `${url.GET_EXPORTADORES}/pagina=${acao.pagina}&tamanho=${acao.tamanho}&nome=${acao.nome ? acao.nome:''}`)

export const createExportador = exportador => post(url.URL_EXPORTADORES, exportador)

export const getExportador = idExportador => get(`${url.URL_EXPORTADORES}${idExportador}`)

export const getExportadoresAll = () => get(`${url.URL_EXPORTADORES}`)

export const editExportador = exportador => put(`${url.URL_EXPORTADORES}${exportador.idExportador}`,exportador)

export const deleteExportador  = idExportador => del(`${url.URL_EXPORTADORES}${idExportador}`)

//produtos
export const getProdutos = acao  =>  
get(
  `${url.GET_PRODUTOS}/pagina=${acao.pagina}&tamanho=${acao.tamanho}&nome=${acao.nome ? acao.nome:''}&descricao=${acao.descricao ? acao.descricao:''}&marca=${acao.marca ? acao.marca:''}&idCliente=${acao.idCliente ? acao.idCliente:''}&codInternoCliente=${acao.codInternoCliente ? acao.codInternoCliente:''}&codOem=${acao.codOem ? acao.codOem:''}&idFornecedor=${acao.idFornecedor ? acao.idFornecedor:''}`)

export const exportarProduto = acao  =>  
get(
  `${url.GET_EXPORTACAO_PRODUTOS}/nome=${acao.nome ? acao.nome:''}&descricao=${acao.descricao ? acao.descricao:''}&marca=${acao.marca ? acao.marca:''}&idCliente=${acao.idCliente ? acao.idCliente:''}&codInternoCliente=${acao.codInternoCliente ? acao.codInternoCliente:''}&codOem=${acao.codOem ? acao.codOem:''}&idFornecedor=${acao.idFornecedor ? acao.idFornecedor:''}`)


export const createProduto = produto => post(url.URL_PRODUTOS, produto)

export const getProduto = idProduto => get(`${url.URL_PRODUTOS}${idProduto}`)

export const editProduto = produto => put(`${url.URL_PRODUTOS}${produto.idProduto}`,produto)

export const getProdutosAll = idCliente => get(`${url.URL_PRODUTOS}idCliente=${idCliente}`)

export const deleteProduto  = idProduto => del(`${url.URL_PRODUTOS}${idProduto}`)

export const deleteAnexo  = anexo => 
del(`${url.URL_PRODUTO_IMAGEM}${anexo.idProduto}/urlImg1=${anexo.img1}&urlImg2=${anexo.img2}&urlDesenhoTec=${anexo.imgDesenhoTec}`)

//orcamento
export const getOrcamentos = acao  =>  
get(
  `${url.GET_ORCAMENTOS}/pagina=${acao.pagina}&tamanho=${acao.tamanho}&dataInicial=${acao.dataInicial ? acao.dataInicial:''}&dataFinal=${acao.dataFinal ? acao.dataFinal:''}&idCliente=${acao.idCliente ? acao.idCliente:''}`)

export const createOrcamento = orcamento => post(url.URL_ORCAMENTOS, orcamento)

export const getOrcamento = idOrcamento => get(`${url.URL_ORCAMENTOS}${idOrcamento}`)

export const editOrcamento = orcamento => put(`${url.URL_ORCAMENTOS}${orcamento.idOrcamento}`,orcamento)

export const deleteOrcamento  = idOrcamento => del(`${url.URL_ORCAMENTOS}${idOrcamento}`)

export const downloadXLSOrcamento  = 
  idOrcamento => 
    postGerarXls(
      `https://app-temp.xdconsulting.com.br/prd/api/gerarArquivos/${idOrcamento}`)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin
}
